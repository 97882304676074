import { Grid } from "@mui/material";
import React from "react";
import ClickThrus from "./ClickThrus";

interface IProps {
  children: React.ReactNode;
}

const CTAs = ({ children }: IProps) => {
  return (
    <Grid container>
      <Grid item md={8} xs={12}>
        {children}
      </Grid>
      <Grid id="col2" item md={4} xs={12} pl={{ md: 4, lg: 5 }}>
        <ClickThrus />
      </Grid>
    </Grid>
  );
};

export default CTAs;
