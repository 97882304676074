import { IObit_Page } from "@/@types/obit-types";
import { Pagination } from "@/components/atoms";
import Ad from "@/components/atoms/Ad/Ad";
import { InitAdScript } from "@/components/common/InitAdScripts";
import MobileOnly from "@/components/common/MobileOnly";
import NonMobileOnly from "@/components/common/NonMobileOnly";
import { PlaceObituaryCTA } from "@/components/common/PlaceObituaryCTA/PlaceObituaryCTA";
import FiltersProvider from "@/components/contexts/FiltersProvider/FiltersProvider";
import styled from "@/components/contexts/ThemeProvider/styled";
import { IndexPageResultsHeader as ResultsHeader } from "@/components/molecules";
import { IndexPageOptions as Options } from "@/components/organisms";
import { hideUntilFontLoaded } from "@/fonts/font-load";
import { sortObitsByDate } from "@/helpers/sort";
import { setStorage } from "@/helpers/storage";
import { fullyQualifiedLink } from "@/helpers/urls";
import { useDomain, usePublication } from "@/hooks/useAppState";
import {
  Theme,
  Typography,
  baseFontFamily,
} from "@gannettdigital/community-hub-components";
import { Box, Grid } from "@mui/material";
import getConfig from "next/config";
import Head from "next/head";
import React, { useEffect, useMemo } from "react";
import NoObits from "./NoObits";
import { ObitListingTop } from "./ObitListingTop";
import Results from "./Results";

const { publicRuntimeConfig } = getConfig();

const ObitListing = ({
  currentPage,
  numPages,
  numResults,
  characters = [],
  obituaries,
}: IObit_Page) => {
  const { name: publicationName } = usePublication();

  const [openMobileAdvancedSearch, setOpenMobileAdvancedSearch] =
    React.useState(false);

  useEffect(() => {
    setStorage("showMePages", numPages as string | number);
  }, [numPages]);

  if (numResults === 0) {
    return (
      <>
        <ObitListingHead
          publicationName={publicationName}
          obituaries={obituaries}
        />
        <NoObits>
          <FiltersProvider>
            <ResultsHeader numResults={numResults} padding={0} />
          </FiltersProvider>
        </NoObits>
      </>
    );
  }

  const openSearch = () => {
    setOpenMobileAdvancedSearch(!openMobileAdvancedSearch);
  };

  return (
    <Container>
      <ObitListingHead
        publicationName={publicationName}
        obituaries={obituaries}
      />

      {/* Note: Load font using FontFace. Using next's Script will not work properly */}
      <script dangerouslySetInnerHTML={{ __html: hideUntilFontLoaded }} />
      <InitAdScript />

      <ObitListingTop handleClickSearchIcon={openSearch} />

      <FiltersProvider>
        <Box display="flex" justifyContent="center" pt={4} pb={6}>
          <NonMobileOnly>
            <PlaceObituaryCTA placement="standard" />
          </NonMobileOnly>
          <MobileOnly>
            <PlaceObituaryCTA isVertical placement="standard" />
          </MobileOnly>
        </Box>

        <Options
          openMobileAdvancedSearch={openMobileAdvancedSearch}
          characters={characters}
        />
        <ResultsHeader numResults={numResults ? numResults : 0} padding={16} />
        {obituaries && <Results obits={obituaries} />}
        <Pagination
          count={numPages ? numPages : 0}
          page={currentPage ? currentPage : 1}
        />
        <Grid item md={8} xs={12}>
          <DisclaimerTypography>
            {publicationName} is not responsible for screening, editing or
            verifying obituary content submitted. The submitter is solely
            responsible for all such content.
          </DisclaimerTypography>
        </Grid>

        <NonMobileOnly>
          <LeaderboardBox>
            <Ad id="leaderboard_btf" type="leaderBoard" plc="leaderboard-btf" />
          </LeaderboardBox>
        </NonMobileOnly>
      </FiltersProvider>
    </Container>
  );
};

export default ObitListing;

type ObitListingHeadProps = {
  publicationName: string;
  obituaries: IObit_Page["obituaries"];
};

const ObitListingHead = ({
  publicationName,
  obituaries,
}: ObitListingHeadProps) => {
  const publicationDomain = useDomain();

  const ogImage = fullyQualifiedLink(
    publicationDomain,
    `${publicRuntimeConfig.assetsPrefix}/static/images/open-graph-poster.jpg`
  );

  const datePublished = useDatePublished(obituaries);

  return (
    <Head>
      <meta
        name="description"
        content={`${publicationName} obituaries and death notices. Remembering the lives of those we've lost.`}
      />
      <meta name="og:image" content={ogImage} />
      <meta name="og:type" content="website" />
      <meta name="datePublished" content={datePublished} />
    </Head>
  );
};

const Container = styled("div")(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(3),

  [theme.breakpoints.up("sm")]: {
    padding: `${theme.spacing(3)} ${theme.spacing(7.5)}`,
  },

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(7.5),
    paddingTop: theme.spacing(1.5),
  },
}));

const DisclaimerTypography = styled(Typography)(() => ({
  fontFamily: baseFontFamily,
  width: "100%",
}));

const LeaderboardBox = styled(Box)(({ theme }) => ({
  display: "none",

  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const useDatePublished = (obituaries: IObit_Page["obituaries"]) => {
  return useMemo(() => {
    const sorted = sortObitsByDate(obituaries || []);

    if (sorted.length === 0) {
      return `${new Date().toISOString().substring(0, 10)}T00:00:00Z`;
    }

    return `${sorted[0].publish_date}Z`;
  }, [obituaries]);
};
