import { SignInMenu } from "@/components/molecules";
import { usePubInfo } from "@/hooks/useAppState";
import { Typography } from "@gannettdigital/community-hub-components";
import { ClickAwayListener } from "@mui/base";
import MuiSearchIcon from "@mui/icons-material/Search";
import { Box, Stack } from "@mui/material";
import { useState } from "react";

interface ObitListingTopProps {
  handleClickSearchIcon?: () => void;
}

export const ObitListingTop = ({
  handleClickSearchIcon = () => {},
}: ObitListingTopProps) => {
  const [open, setOpen] = useState(false);

  const pubInfo = usePubInfo();
  const hasPubInfo = pubInfo?.email && pubInfo?.phone;

  if (!hasPubInfo) {
    return null;
  }

  const handleToggle = () => setOpen(!open);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Stack
        pt={{ sx: 0, sm: 3 }}
        direction={{ xs: "column-reverse", sm: "row" }}
      >
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h1">Obituaries</Typography>

          {handleClickSearchIcon && (
            <Box
              display={{ xs: "inline-flex", sm: "none" }}
              justifyContent="flex-end"
            >
              <MuiSearchIcon onClick={handleClickSearchIcon} />
            </Box>
          )}
        </Stack>

        <Box display="inline-flex" justifyContent="flex-end">
          <SignInMenu open={open} onToggle={handleToggle} />
        </Box>
      </Stack>
    </ClickAwayListener>
  );
};
